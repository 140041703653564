<template>
  <div class="container-fluid pb-3" style="margin-top:15px">
  <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-lg-12">
        <template v-if="knowledgeBaseCategoryList">  <!--  && granted_ticket_add_edit == 1" -->
          <template v-if="formErrors.length">
            <div class="alert alert-danger alert--grouped mt-3">
              <p>
                <i class="fe fe-alert mr-2"></i><strong>Błąd! Proszę poprawić następujące błędy formularza:</strong>
              </p>
              <ul class="mb-0">
                <li v-for="error in formErrors" :key="error">{{ error }}</li>
              </ul>
            </div>
          </template>
          <div class="form-group">
            <label for="ticket-title">Tytuł zgłoszenia</label>
            <input v-model="formTitle" type="text" class="form-control" id="ticket-title" placeholder="Podaj tytuł zgłoszenia">
          </div>
          <div class="form-group">
            <label for="ticket-category">Kategoria</label>
            <select v-model="formCategory" class="custom-select" id="ticket-category">
                <template v-for="item in knowledgeBaseCategoryList">
                  <option :key="item.uuid" v-bind:value="item.uuid">{{ item.title }}</option>
                </template>
            </select>
          </div>
          <div class="form-group">
            <label for="ticket-message">Wiadomość</label>
            <textarea v-model="formMessage" class="form-control" id="ticket-message" rows="3" placeholder="Opisz zgłoszenie"></textarea>
          </div>
          <!-- <div class="form-group">
            <div class="custom-file">
              <input @change="onFileChanged" type="file" class="custom-file-input" id="ticket-attachment" accept="image/*" multiple="multiple">
              <label class="custom-file-label" for="ticket-attachment" data-browse="Przeglądaj">{{ fileName ? fileName : 'Wybierz plik'}}</label>
            </div>
          </div> -->
          <div class="form-group text-right">
            <button class="basic-info__add-btn btn-sm" @click="onSendTicket()">Wyślij zgłoszenie</button>
          </div>
        </template>
        <template v-else>
          <div v-if="hasError" class="alert alert-warning alert--grouped mt-3">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br/>Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
          <div v-else class="loader"></div>
        </template>
      </div>
      <!-- <custom-alert></custom-alert> -->
    </div>
    <div v-if="isUpdated" class='loader-container'>
        <div  class="loader loader--fixed"></div>
    </div>
  </div>
</template>

<script>
// import jwtDecode from 'jwt-decode';
import { baseUrl } from '@/axios-config';
import { EventBus } from '@/main';
// import Alert from '../Alert.vue';
import { mapActions, mapState} from 'vuex'
export default {
  components: {
    // 'custom-alert': Alert,
  },
  data: function() {
    return {
      // granted_ticket_add_edit: 0,
      files: [],
      fileName: null,
      formTitle: '',
      formMessage: '',
      formCategory: '',
      formErrors: [],
      dataBaseUrl: baseUrl,
      isUpdated: false,
    };
  },
  created: function() {
    this.$Progress.start();
    this.getKnowledgeBaseCategories()
    .then(()=> this.$Progress.finish())
    .catch((error)=>{
      this.$Progress.fail();
      console.error('Error:', error);
    });
  },
  computed: mapState('tickets', ['totalItems', 'knowledgeBaseCategoryList', 'hasError']),
  methods: {
    ...mapActions('tickets', ['getKnowledgeBaseCategories', 'sendTicket']),

    onFileChanged (event) {
      this.files = event.target.files;
      this.filesCount = this.files.length;
      if (this.filesCount > 0) {
        this.fileName = 'Wybranych plików: ' + this.filesCount;
      }
    },
    onSendTicket () {
      // let formData = new FormData();
      // formData.append('file', this.files);
      this.formErrors = [];
      if (!this.formTitle) {
        this.formErrors.push('Pole Tytuł zgłoszenia jest wymagane.');
        EventBus.$emit('triggerAlert', { message: `Błąd! Uzupełnij pole tytułu zgłoszenia.`, triggerManualError: true });
      }
      if (!this.formCategory) {
        this.formErrors.push('Kategoria zgłoszenia nie została wybrana.');
        EventBus.$emit('triggerAlert', { message: `Błąd! Wybierz kategorię.`, triggerManualError: true });
      }
      if (!this.formMessage) {
        this.formErrors.push('Pole wiadomości jest wymagane.');
        EventBus.$emit('triggerAlert', { message: `Błąd! Uzupełnij pole wiadomości.`, triggerManualError: true });
      }
      if (this.formErrors.length === 0) {
        this.$Progress.start();
        this.isUpdated = true;
        this.sendTicket({
          formTitle: this.formTitle,
          formMessage: this.formMessage,
          formCategory: this.formCategory
        }).then(()=>{
          this.$Progress.finish();
          this.formTitle = null;
          this.formMessage = null;
          this.formCategory = null;
          this.$router.push('/tickets');
        }).catch(error=>{
          this.$Progress.fail();
          console.error(error);
        }).finally(()=>{
          this.isUpdated = false;
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
